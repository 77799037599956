import React, { useRef, useState, useEffect } from 'react'
import Formsy from 'formsy-react';
import axios from "axios"
import FormsyInput from "./../Formsy/formsyInput"
import { useDropzone } from 'react-dropzone';



const SendResume = (props) => {

    const formRef = useRef(null);

    const [canSubmit, setCanSubmit] = useState(false);
    const [canSubmit2, setCanSubmit2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [privacyTermsStatus, setPrivacyTermsStatus] = useState(false);
    const [cvOptIn, setCvOptIn] = useState(false);

    const [files, setFiles] = useState([]);
    const [emailSent, setEmailSent] = useState(false);
    const [emailFailed, setEmailFailed] = useState(false);
    const [emailResponse, setEmailResponse] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'application/pdf,.doc,.docx',
        maxSize: 5242880,
        multiple: false,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        },
        onDropRejected: (e) => {
            console.log(e)
            console.log("Invoked");
        }
    });

    useEffect(() => {
        if (privacyTermsStatus && canSubmit && (!cvOptIn && (!props.allowCv && files.length <= 0))) {
            setCanSubmit2(true)
        } else if ((privacyTermsStatus && canSubmit && (cvOptIn && files.length > 0))) {
            setCanSubmit2(true)
        } else {
            setCanSubmit2(false)
        }
    }, [privacyTermsStatus, cvOptIn])


    function disableButton() {
        setCanSubmit(false);
    }

    function enableButton() {
        setCanSubmit(true);
    }

    function submit(model) {
        if ((model.lastName && model.name && model.email) && (files.length > 0)) {
            sendApplication(model, files);
        } else {
            setShowErrorMessage(true)
        }
    }

    function sendApplication(model, files) {
        document.getElementById('applicant-form').scrollIntoView();
        setLoading(true)

        let formData = new FormData();
        if (files) {
            formData.append('file', files[0]);
        }
        formData.append('model', JSON.stringify(model));
        formData.append('jobName', "Γενικό Ενδιαφέρον");

        axios.post(`https://admin.w3vitals.com/wp-json/myplugin/v1/addApplicant`, formData)
            .then(function (response) {
                console.log(response)
                // handle success
                setEmailFailed(false)
                setLoading(false)
                setEmailSent(true)
                setEmailResponse(response.data)
            })
            .catch(function (error) {
                // handle error
                setLoading(false)
                setEmailFailed(true)
                setEmailResponse("Υπήρξε πρόβλημα κατα την αποστολή. Δοκιμάστε αργότερα")
            })
    }

    function test(e) {
        e.preventDefault();
        setShowErrorMessage(true)
    }

    function resetFile() {
        setFiles([]);
    }


    return (
        <section className="case-studies-details-area" style={{position: "relative"}}>
            <div className='career-devider-bot' />
            <div className="ptb-100" id="applicant-form" style={{ backgroundColor: "#f1f8fb" }}>
                <div className="container"  style={{ maxWidth: "650px",position: "relative" }}>
                    {loading && (
                        <div className="loader-container">
                            <div className="loader">Loading...</div>
                        </div>
                    )}

                    {emailFailed && (
                        <p style={{ color: "#b70000", fontSize: "1.2rem" }}>{emailResponse}</p>
                    )}

                    {!emailSent
                        ?
                        <Formsy
                            onValidSubmit={submit}
                            onValid={enableButton}
                            onInvalid={disableButton}
                            className={loading ? "opacity-25" : "opacity-100"}
                            ref={formRef}
                        >
                            <h2 className="text-4xl text-center">
                                Αποστολή Βιογραφικού
                                </h2>
                            <div>
                                <p className="text-md text-center">
                                Έλεγξες τις διαθέσιμες θέσεις εργασίας μας και δε βρήκες κάτι που ταιριάζει στα προσόντα και τις δεξιότητές σου, αλλά θέλεις να σε έχουμε υπόψιν για μελλοντικές θέσεις που τυχόν προκύψουν; Στείλε μας το βιογραφικό σου με την παρακάτω φόρμα.
                            </p>
                            </div>
                            <div className="mt-12">
                                <FormsyInput
                                    name="name"
                                    placeholder="Όνομα *"
                                    classes="mt-4"
                                    required
                                />
                                <FormsyInput
                                    name="lastName"
                                    placeholder="Επώνυμο *"
                                    classes="mt-4"
                                    required
                                />
                                <FormsyInput
                                    name="email"
                                    validations={"isEmail"}
                                    validationErrors={{
                                        isEmail: "Το email δεν είναι έγκυρο",
                                    }}
                                    placeholder="Email *"
                                    classes="mt-4"
                                    required
                                />

                                <FormsyInput
                                    name="telephone"
                                    validations={"minLength:10"}
                                    type="tel"
                                    validationErrors={{
                                        minLength: "Το τηλέφωνο δεν είναι έγκυρο",
                                    }}
                                    placeholder="Τηλέφωνο"
                                    classes="mt-4"
                                />

                                <div className="text-white mt-5" {...getRootProps({ className: "dropzone" })}>
                                    <input {...getInputProps()} />
                                    <img src={props.icon ? props.icon : null} style={{ width: "150px" }} />
                                    <p>Μεταφόρτωση Βιογραφικού *</p>

                                    {files.length > 0 && (
                                        <span>
                                            {files[0].name}
                                        </span>
                                    )}
                                </div>

                                {files.length > 0 && (
                                    <div onClick={resetFile} className="clear-cv-w">Καθαρισμός</div>
                                )}

                                <div className="mt-4">
                                    <input
                                        name="privacy-terms"
                                        type="checkbox"
                                        checked={privacyTermsStatus}
                                        onChange={() => setPrivacyTermsStatus(!privacyTermsStatus)}
                                    />
                                    <span className="pl-2">Αποδέχομαι την
                                            <a href="https://w3vitals.com/politiki-aporritou/" target="_blank" className="px-1 underline" style={{ color: "#DB3A00" }}>Πολιτική Απορρήτου</a>
                                            και τους
                                            <a href="https://w3vitals.com/oroi-kai-proipotheseis/" target="_blank" className="pl-1 underline" style={{ color: "#DB3A00" }}>Όρους και Προϋποθέσεις Χρήσης</a>.
                                        </span>
                                </div>

                                {files.length > 0 && (
                                    <div>
                                        <input
                                            name="privacy-terms"
                                            type="checkbox"
                                            checked={cvOptIn}
                                            onChange={() => setCvOptIn(!cvOptIn)}
                                        />
                                        <span className="pl-2 pt-2">Συμφωνώ με την αποθήκευση και επεξεργασία του βιογραφικού μου σημειώματος και των προσωπικών δεδομένων που εμπεριέχονται σε αυτό για την πλήρωση της θέσης και μελλοντικές θέσεις οι οποίες ενδέχεται να προκύψουν.</span>
                                    </div>
                                )}

                            </div>
                            <div>
                                {(canSubmit && canSubmit2)
                                    ?
                                    <button className={"default-btn mt-3"} style={{ paddingLeft: '25px' }}>
                                        Υποβολή
                                    </button>
                                    :
                                    <button className={"default-btn mt-3"} style={{ paddingLeft: '25px' }} onClick={(e) => test(e)}>
                                        Υποβολή
                                    </button>
                                }
                            </div>
                            {showErrorMessage && (
                                <p className="text-center mt-8" style={{ color: "#b70000", fontSize: "1.2rem", }}>Συμπληρώστε όλα τα απαραίτητα πεδία (*)</p>
                            )}
                        </Formsy>

                        :
                        <div >
                            <div className="email-response" dangerouslySetInnerHTML={{ __html: emailResponse }} />
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}

export default SendResume