import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import SendResume from '../components/Career/SendResume'
import IntroCareer from '../components/Career/IntroCareer'
import AvailableJobs from '../components/Career/AvailableJobs'



const Career = (props) => {
    let data = props.data.wpgraphql;

    console.log(data)

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://w3vitals.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://w3vitals.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <Layout metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}>
            <Navbar header={props.pageContext.headerMenu}  lang={props.pageContext.language} />
            {/* <PageBanner
                pageTitle={"Career"}
                homePageText="Αρχική"
                homePageUrl={props.pageContext.language ==="EN" ? "/en/" : (props.pageContext.language ==="FR" ? "/fr/" : "/")}
                activePageText={"Career"}
            /> */}
            <IntroCareer
                introductionMobileImage={data.page.careerPageAcf.introductionMobileImage}
                introductionImage={data.page.careerPageAcf.introductionImage}
                introductionHeader={data.page.careerPageAcf.introductionHeader}
                introductionParagraph={data.page.careerPageAcf.introductionParagraph}
            />
            <AvailableJobs jobs={data.jobs.edges} data={data.page.careerPageAcf.jobsGroup} />
            <SendResume />
            <Footer footer={props.pageContext.footerMenu} extra={data.footerTemplate.footerTemplateAcf} />
        </Layout>
    );
}

export default Career

export const pageQuery = graphql`
    query GET_CAREER($id: ID! , $footerTemplateUri: ID!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
			    careerPageAcf {
                    introductionImage{
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp {
                                fluid(maxWidth: 450){
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    introductionMobileImage{
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp {
                                fluid(quality:100){
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    introductionHeader
                    introductionParagraph
                    jobsGroup{
                        title
                        subtitle
                    }

                }
            }
            jobs(first:100,where:{notIn:[16687]}){
                edges{
                    node{
                        title
                        content
                        slug
                        jobAcf{
                            specificLocation
                            workingHours
                        }
                    }
                }
            }
            footerTemplate: template(id: $footerTemplateUri, idType: SLUG) {
                footerTemplateAcf{
                    addressTitle
                    addressContent
                    content
                }
            }
        }
    }
`