import React from 'react'
// import ReactWOW from 'react-wow'
// import ModalVideo from 'react-modal-video'
import { Link } from 'gatsby'
// import Helmet from "react-helmet"

import FlatStructure from "../../assets/images/icons/structure.svg"


const IntroCareer = (props) => {

    return (
        <React.Fragment>
            {/* <Helmet>
                <link rel="preload" as="image" href={`https://fervent-jennings-a32d07.netlify.app/static/main-banner-bg2-mob-b616826179a841ddd47bbe8659bce1f0.png`} />
            </Helmet> */}
            <div className="banner-section banner-section-career relative">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="banner-content banner-content-career">
                                {/* <div delay='.1s' animation='fadeInLeft'> */}
                                <h1 className="cs-dark-text">{props.introductionHeader}</h1>
                                {/* </div> */}

                                {/* <div delay='.1s' animation='fadeInLeft'> */}
                                <p className="cs-dark-text">{props.introductionParagraph}</p>
                                {/* </div> */}

                                <div delay='.1s' animation='fadeInRight'>
                                    <div className="btn-box">
                                        <button
                                            onClick={() => document.getElementById('available-jobs').scrollIntoView() } 
                                            className="default-btn"
                                        >
                                            <img src={FlatStructure} className="btn-svg" alt="structure icon" />
                                            Θέσεις Εργασίας <span></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12">
                            <div style={{maxWidth: "450px",margin: "auto"}}>
                                <div className="d-none d-md-block d-xl-block career-intro-img">
                                    <picture>
                                        <source type="image/webp" srcSet={props.introductionImage.imageFile.childImageSharp.fluid.srcSetWebp} importance="high" />
                                        <source type="image/jpg" srcSet={props.introductionImage.imageFile.childImageSharp.fluid.srcSet} />
                                        <img
                                            alt={props.introductionImage.altText}
                                            style={{ top: "0", left: "0" }}
                                        />
                                    </picture>
                                </div>

                                <div className="d-block d-md-none d-xl-none mt-5 " style={{minHeight: "210.5px"}}>
                                    <picture>
                                        <source type="image/webp" srcSet={props.introductionImage.imageFile.childImageSharp.fluid.srcSetWebp} importance="high" />
                                        <source type="image/jpg" srcSet={props.introductionImage.imageFile.childImageSharp.fluid.srcSet} />
                                        <img
                                            alt={props.introductionImage.altText}
                                            style={{ top: "0", left: "0" }}
                                        />
                                    </picture>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='career-devider' />
            </div>
        </React.Fragment>
    )
}

export default IntroCareer
