import React from 'react'
import { Link } from 'gatsby'
import shape9 from '../../assets/images/shape/vector-shape9.png'
import shape10 from '../../assets/images/shape/vector-shape10.png'


const AvailableJobs = (props) => {
    return (
        <div id="available-jobs" className="project-start-area bg-color ptb-100" style={{ minHeight: "500px" }}>
            <div className="section-title">
                <span className="d-block text-white sub-title">{props.data.subtitle}</span>
                <h2 className="text-white">{props.data.title}</h2>
            </div>
            <div className="m-auto px-4" style={{ maxWidth: "700px"}}>
                <div>
                    {props.jobs && props.jobs.map((job, i) => (
                        <div className="d-flex justify-content-between bg-white p-4 mt-4" style={{borderRadius: "10px"}} key={`jobs-${i}`}>
                            <div>
                                <div style={{fontSize:"1.3rem"}}>
                                    {job.node.title}
                                </div>
                                <div style={{fontSize:"1rem",color: "#db3a00"}}>
                                    <span>{job.node.jobAcf.specificLocation}</span>
                                    <span className="pl-2">{job.node.jobAcf.workingHours}</span>
                                </div>
                            </div>
                            <div>
                                <Link to={`/career/${job.node.slug}`} className="default-btn" style={{paddingLeft:"25px"}}>Αίτηση</Link>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <div className="vector-shape9">
                <img src={shape9} alt="about" />
            </div>
            <div className="vector-shape10">
                <img src={shape10} alt="about" />
            </div>
        </div>
    )
}

export default AvailableJobs
